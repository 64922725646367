/*
  ----****----
  author
  Nikunj Pansuriya
  ----****----
*/

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.visual_search {
  position: relative;
}

.visual_search .visual_search_wrapper {
  display: flex;
  width: 100%;
}

.visual_search .search_input {
  border: none;
  width: 100%;
  height: 40px;

  font-size: 15px;

}
.visual_search .search_input::placeholder {
  color: #999999;
}

.visual_search .visual_input_wrapper {
  position: relative;
  display: block;
  margin: 0px 0px 0px 4px;
}

.visual_search .visual_input_wrapper .options_wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 40px;
  left: 0px;

  min-width: 120px;

  padding: 8px 0px;

  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: white;

  z-index: 999;
}
.visual_search .visual_input_wrapper .options_wrapper .option {
  padding: 4px 8px;

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  color: #333333;

  cursor: pointer;
}
.visual_search .visual_input_wrapper .options_wrapper .option.selected {
  color: #666666;
  text-decoration: line-through;

  pointer-events: none;
  background-color: #f7f7f7;
}
.visual_search .visual_input_wrapper .options_wrapper .option:hover {
  background-color: #eeeeee;
  font-weight: 600;
}

.visual_search .search_input:focus {
  outline: none;
}

.visual_search #visual_search_option_list {
  margin-top: 0px;
  border-radius: 0px;
  left: 0px !important;
  max-width: none;
  width: 100%;
}

.visual_search .popover-content {
  padding: 0px;
}

.visual_search .arrow {
  display: none;
}

#visual_search_option_list .option {
  display: block;
  padding: 5px 5px 5px 5px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.visual_search .search_value_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 12px;

  margin: 0px 8px 0px 0px;

  border-radius: 4px;
  background-color: #E8F5E9;
}
.visual_search .input_list_container {
  height: 24px;
}
.visual_search .input_text_value {
  width: 0px;
  height: 24px;

  padding: 0px;
  margin: 0px;

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  opacity: 0;
  border: none;
}

.visual_search .input_text_value.focused {
  width: 80px;
  margin: 0px 0px 0px 4px;
  padding: 0px 4px;
  opacity: 1;
}

.visual_search .input_text_value:focus {
  outline: none;
}

.search_value_wrapper .search-label {
  display: flex;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.search_value_wrapper .search-label::after {
  position: static;
  display: inline-block;
  content: 'is';

  margin: 0px 4px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.search_value_wrapper .search-value {
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  cursor: text;
}

.search_value_wrapper .value_close {
  display: block;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  padding: 3px;
  line-height: 14px;

  margin: 0px 0px 0px 12px;
  
  border-radius: 20px;
  background-color: #43A047;

  cursor: pointer;
}

.popover_options {
  position: absolute;
  display: flex;
  flex-direction: column;

  min-width: 112px;

  padding: 4px 0px;

  margin-top: 0px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  left: 4px !important;
  /* top: 40px !important;
  left: unset !important;
  right: 12px !important; */

  z-index: 999;

  background-color: white;
}

.popover_options .option {
  display: block;
  padding: 4px 8px;

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  color: #333333;

  cursor: pointer;
}
.popover_options .option:hover {
  background-color: #eeeeee;
  font-weight: 600;
}

.popover_options .arrow {
  display: none;
}

.pickeryear {
  padding: 10px;
}

.pickeryear:hover {
  background-color: #00a7de;
  color: #fff;
  cursor: pointer;
}

.pickermonth {
  padding: 10px;
}

.pickermonth:hover {
  background-color: #00a7de;
  color: #fff;
  cursor: pointer;
}

.pickerdate:hover {
  background-color: #00a7de;
  color: #fff;
  cursor: pointer;
}

.pickerheader {
  margin-top: 2px;
}

.pickerheader .spanbtn {
  padding: 2px;
  cursor: pointer;
}

.pickertable {
  margin-bottom: 0px;
}

.pickertable tr th {
  padding: 2px !important;
  text-align: center;
  font-size: 12px;
}

.pickertable tr td {
  padding: 2px !important;
  border-top: none !important;
}

.pickertable tr td.pickerdate {
  cursor: pointer;
}