body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #E5E6E7;
  font-family: 'Roboto', sans-serif;

  user-select: none;
}

*, *::before, *::after {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

#recharts_measurement_span {
  font-size: 10px !important;
}